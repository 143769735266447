<template>
  <div>
    Data upload page
    <div v-if="alertflag">
      <v-alert text color="info">
        <v-row align="center" no-gutters>
          <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn cols="12" xs="1" sm="1" md="1" lg="1" color="info" outlined @click="alertflag = false"> Okay </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarmsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
      </template>
    </v-snackbar>
    <v-textarea
      filled
      auto-grow
      label="plese enter the activity list"
      rows="4"
      row-height="30"
      shaped
      v-model="uploadlist"
    ></v-textarea>

    <v-select v-model="options" label="Options" :items="uploadmenus" required></v-select>
    <v-btn small color="secondary" @click="uploadlists"> upload </v-btn>
  </div>
</template>

<script>
import { activityRef, assetRef, vendorRef } from "../../fb"
import { formMixin } from "../../mixins/formMixin.js"
import { convertAll } from "../../mixins/convertall.js"
import { newUserMixin } from "../../mixins/newUserMixin.js"
export default {
  name: "uploads",
  mixins: [formMixin, convertAll, newUserMixin],
  data: () => ({
    activitylist: "",
    uploadlist: "",
    numberOfField: [6, 10, 8, 8],
    uploadmenus: ["Activity", "Asset", "Vendor", "Users"],
    options: "",
    alertflag: false,
    snackbar: false,
    snackbarmsg: "",
    alertmsg: "",
    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
  }), //end of data
  methods: {
    async uploadlists() {
      var lists = []
      var i,
        count = 0,
        j,
        k = 0
      var list = ""

      var field
      this.alertflag = false
      if (this.options == "") {
        this.alertflag = true
        this.alertmsg = "please select upload option from list"
        return
      }

      var requiredfield = this.numberOfField[this.uploadmenus.indexOf(this.options)]
      console.log("requiredfield", requiredfield)

      for (i = 0; i < this.uploadlist.length; ++i) {
        if (this.uploadlist[i] == "\n") {
          lists[count] = list
          count++
          list = ""
        } else {
          list = list + this.uploadlist[i]
        }
      } // end of line split
      var values = new Array(count)
      for (i = 0; i < count; ++i) {
        console.log("after lines split", i, lists[i])
      }
      for (i = 0; i < count; ++i) {
        list = lists[i]
        k = 0
        field = ""
        var numberOfFields = list.split("|").length - 1
        console.log("numberOfFields", numberOfFields)
        if (requiredfield == numberOfFields) {
          values[i] = new Array(numberOfFields)

          for (j = 0; j < list.length; ++j) {
            if (list[j] == "|") {
              //console.log(" inside |",i,k,field)
              values[i][k] = field
              k++
              field = ""
            } else {
              field = field + list[j]
            }
          } //end of for to get the field for a list.
        } // end of  if(requiredfield==numberOfFields)
        else {
          this.alertflag = true
          i = i + 1
          this.alertmsg =
            "fields numbers mistach in line : " +
            i +
            ",Requiredfields: " +
            requiredfield +
            ",Availablefields: " +
            numberOfFields
          return
        }
      } //end of for to get the field for all lists
      for (i = 0; i < count; ++i) {
        try {
          console.log("values", i, values[i])
          if (this.options == "Activity") {
            activityRef
              .add({
                comments: values[i][4],
                domain: values[i][5],
                lastService: values[i][1],
                name: values[i][0],
                nextService: values[i][2],
                status: values[i][3],
              })
              .then((docRef) => {
                console.log("data uploade for record: ", i, docRef)
              })
          } else if (this.options == "Asset") {
            assetRef
              .add({
                type: values[i][0],
                id: values[i][1],
                make: values[i][2],
                serialNumber: values[i][3],
                assignTo: values[i][4],
                purchaseDate: values[i][5],
                insuranceExpiryDate: values[i][6],
                theftInsrances: values[i][7],
                comments: values[i][8],
                domain: values[i][9],
              })
              .then((docRef) => {
                console.log("data uploade for record: ", i, docRef)
              })
          } else if (this.options == "Vendor") {
            vendorRef
              .add({
                name: values[i][0],
                type: values[i][1],
                contactPerson: values[i][2],
                contactNumber: values[i][3],
                alternateNumber: values[i][4],
                status: values[i][5],
                address: values[i][6],
                domain: values[i][7],
              })
              .then((docRef) => {
                console.log("data uploade for record: ", i, docRef)
              })
          } else if (this.options == "Users") {
            /* var tmppassword=Math.floor(100000 + Math.random() * 900000);
            var password=tmppassword.toString();
           var roles=values[i][4].split(",");
            var email=values[i][2]
            var empid=values[i][0]
            var domain=values[i][7];*/

            var newUser = {}

            console.log("roles", values[i][2], values[i][0], values[i][4])

            newUser.email = values[i][2]
            newUser.empid = values[i][0]
            newUser.status = values[i][3]
            newUser.newroles = values[i][4].split(",")
            newUser.name = values[i][1]
            newUser.mgrName = values[i][5]
            newUser.mgremail = values[i][6]
            newUser.domain = values[i][7]
            let Ret = await this.addUser(newUser)
            if (Ret == true) {
              console.log("user updated successfully", values[i][2])
            } else {
              console.log("user update failure", values[i][2])
            }
          } else {
            this.alertflag = true
            this.alertmsg = "option not found" + this.options
          }
        } catch (error) {
          //end of try
          this.alertflag = true
          this.alertmsg = "upload failure " + error
        }
      } // end of for

      this.snackbar = true
      this.snackbarmsg = "Data uploaded successfully"
      /*  name:doc.data().name,
                  type:doc.data().type,
                  contactPerson:doc.data().contactPerson,
                  contactNumber:doc.data().contactNumber,
                  alternateNumber:doc.data().alternateNumber,
                  status:doc.data().status,
                  address:doc.data().address,
                  domain:doc.data().domain,*/
    },

    listactivity() {
      var lists = []
      var i,
        count = 0
      var j,
        k = 0
      var list = ""
      var fields = []
      var field
      var actvityname = ""
      var lastservicedate = ""
      var nextservicedate = ""
      var status = ""
      var comments = ""
      var domain = ""

      for (i = 0; i < this.activitylist.length; i++) {
        if (this.activitylist[i] == "\n") {
          lists[count] = list
          count++
          list = ""
        } else {
          list = list + this.activitylist[i]
        }
      } // end of line check and parse the line by line

      for (i = 0; i < count; ++i) {
        list = lists[i]
        //console.log("list[",i,"]",list)
        actvityname = ""
        lastservicedate = ""
        nextservicedate = ""
        status = ""
        comments = ""
        domain = ""
        k = 0
        field = ""
        console.log("| in list", list.split("|").length - 1)

        for (j = 0; j < list.length; ++j) {
          if (list[j] == "|") {
            fields[k] = field
            switch (k) {
              case 0:
                actvityname = field
                break
              case 1:
                lastservicedate = field
                break
              case 2:
                nextservicedate = field
                break
              case 3:
                status = field
                break
              case 4:
                comments = field
                break
              case 5:
                domain = field
                break
            }
            k++
            field = ""
          } else {
            field = field + list[j]
          }
        } // end of each activity.
        console.log("list", i, actvityname, lastservicedate, nextservicedate, status, comments, domain)
        activityRef.add({
          comments: comments,
          domain: domain,
          lastService: lastservicedate,
          name: actvityname,
          nextService: nextservicedate,
          status: status,
        })
      }
      alert("upload completed successfully")
    },
  }, //end of method
} // end of script
</script>
