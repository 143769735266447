import axios from "axios"
import { usersRef, userconfig, authdomain, adduserurl, sendmailurl, updateUserRolesurl,configgenRef,emailRef } from "../fb"

//raja@venzoconsulting.com
export const newUserMixin = {
  data: function () {
    return {
      retobj: { uid: "", status: "", userconfig: "", error: "" },
    }
  },

  methods: {
    async addUser(body) {
      var tmppassword = Math.floor(100000 + Math.random() * 900000)
      body.passwd = tmppassword.toString()
      console.log("inside addUser", body)

      await this.addcallpost(body)

      console.log("addUser retobj ", this.retobj)
      return this.retobj
    }, // end of addUser method
    async addcallpost(body) {
      try {
        console.log("inside addcallpost newuser mixin", adduserurl, body)
        var Ret = await axios.post(adduserurl, body)
        console.log("in callpost", Ret)
        console.log("new user uid", Ret.data.uid)
        Ret = await axios.post(updateUserRolesurl, body)
        Ret = await this.addTableData(body)
        console.log("callpost retobj ", this.retobj)
        return
      } catch (err) {
        console.log(err)
        this.retobj.status = false
        return
      }
    }, // end of callpost
    async addTableData(body) {
      try {
        var retobj = {}
        await usersRef
          .add({
            /*  empid:body.empid,
          email:body.email,
          status:body.status,
          role:body.role,
          name:body.name,
          mgrName:body.mgrName,
          mgremail:body.mgremail,
          domain:body.domain,
          createdOn:new Date().toISOString().substr(0, 10),
          userconfig:userconfig,*/
            ...body,
            userconfig: userconfig,
          })
          .then((docRef) => {
            this.addmailfun(body)
            this.retobj.status = true
            this.retobj.uid = docRef.id
            this.retobj.userconfig = userconfig
            console.log("addTableData retobj ", retobj)
            return
          })
      } catch (err) {
        this.retobj.status = false
        this.retobj.error = err
        return
      }
    }, // end of addTableData

    async addmailfun(body) {
      console.log("inside mailfun", body)
      var mailmsg = {}
      mailmsg.to = body.email

      var queryresult=await configgenRef.where("domain" ,"==", body.domain).where("description" , "==","OnBoard Mail to HR").get();
      console.log("queryresult-configgenRef",queryresult)
      if(queryresult.docs.length>0){
        var tmpflag=queryresult.docs[0].data().value;
        if(tmpflag=="YES"){
           queryresult=await emailRef.where("domain" ,"==", body.domain).get();
           console.log("queryresult-emailRef",queryresult)
           
           if(queryresult.docs.length>0){
            mailmsg.to=queryresult.docs[0].data().hradminemail
           }

        }
      }
      mailmsg.subject = "Welcome to " + body.domain + " new employee id"
      mailmsg.message =
        "Hi, your employee id is: " +
        body.empid +
        ", please login in to " +
        body.domain +
        " portal and update the details."
      mailmsg.message = mailmsg.message + " The user name is your email address and password is:" + body.passwd + ". "
      mailmsg.message = mailmsg.message + '<a href="' + authdomain + '">Click here to access the portal</a>'
      console.log(mailmsg)
      // this.sendEmail(mailmsg);
      await axios
        .post(sendmailurl, mailmsg)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /* async addUser(body) {
      console.log("inside addUser")
      await axios
        .post("https://us-central1-venzoadmindev.cloudfunctions.net/addUser", body)
        .then((res) => {
          console.log(res)
          try{
            usersRef.add({
            empid:body.empid,
            email:body.email,
            status:body.status,
            role:body.newroles,
            name:body.name,
            mgrName:body.mgrName,
            mgremail:body.mgremail,
            domain:body.domain,
          
            createdOn:new Date().toISOString().substr(0, 10),
            userconfig:body.userconfig,
            }).then(docRef => {
             console.log(docRef)
             return true
            })
          }
          catch (err)
          {
            console.log(err)
            return err
          }

          
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },*/ // end of add user
  },
}
//sendMail
